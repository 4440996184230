<template>
	<div class="">
		<div class="page-content">
			<el-breadcrumb separator=">" style="position: absolute;margin-top: -4px;">
				<el-breadcrumb-item :to="{ path: '/index' }">Home</el-breadcrumb-item>
				<el-breadcrumb-item>Digests</el-breadcrumb-item>
			</el-breadcrumb>
			<div>
				<el-input v-model="searchValue" placeholder="Search by Title, Author, Keyword or Doi"
					class="input-with-select" style="border: solid 1px #6b57a5;">
					<template #prepend>
						<el-select v-model="searchType"
							style="width: 115px;background-color: #f3f7ff;color: #333333;margin-left: -21px;">
							<el-option label="Title" value="Title" />
							<el-option label="Author" value="Author" />
							<el-option label="Keyword" value="Keyword" />
							<el-option label="Doi" value="Doi" />
						</el-select>
					</template>
					<template #append>
						<el-button type="primary" @click="searchData()"
							style="border-radius: 0px;margin-left: -82px;margin-top: -20px;position: absolute;color: white; background: rgb(107, 87, 165);">
							<el-icon style="vertical-align: middle;">
								<Search />
							</el-icon>
							<span style="vertical-align: middle;"> Search
							</span>
						</el-button>
					</template>
				</el-input>
			</div>
			<div style="display: flex;">
				<div style="width: 850px;margin-right: 30px;">
					<div style="width: 850px;height: 180px;background-color: #f3f9fc;margin-top: 50px;display: flex;">
						<div style="padding-left: 20px;">
							<p style="height: 107px;width: 570px;text-align: justify;" class="five">{{details.ti}}</p>
							<p>
								<el-link :underline='false' :href="details.dl" target="view_window">
									Web:{{details.dl}}
								</el-link>
							</p>
						</div>
						<div style="padding: 20px 10px 20px 10px;">
							<el-image style="width: 90px;height: 120px;"
								:src="'http://www.sciencealerts.org/qkima/images/'+details.sn+'.jpg'">
							</el-image>
						</div>
						<div style="">
							<p style="height: 53px;font-size: 14px;" class="two">{{details.so}}</p>
							<div style="line-height: 7px;font-size: 13px;">
								<p><span
										style="font-weight: bold;margin-right: 5px;">ISSN:</span><span>{{details.sn}}</span>
								</p>
								<p><span
										style="font-weight: bold;margin-right: 5px;">e-ISSN:</span><span>{{details.ei}}</span>
								</p>
								<p><span style="font-weight: bold;margin-right: 5px;">Impact
										Factor:</span><span>暂无</span></p>
							</div>
						</div>
					</div>
					<div style="margin-top: 30px;border: solid 1px #eeeeee;min-height: 500px;">
						<div
							style="width: 68px;height: 57px;background-color: #6b57a5;border-radius: 0px 10px 10px 0px;float: left;margin-top: 20px;">
							<p
								style="padding-left: 22px;white-space: nowrap;font-family: Arial-BoldMT;font-weight: bolder;">
								<span style="color: white;">Field:</span> Engineering
							</p>
						</div>
						<div style="float: right;margin-right: 20px;margin-top: 20px;">
							<el-button type="danger" style="background-color:#6b57a5;border-color: #6b57a5;"
								@click="cite=true">Cite
							</el-button>
							<p>
								<el-icon style="font-size: 40px;color: #f56c6c;margin-left: 26px;">
									<Unlock />
								</el-icon>
							</p>
						</div>
						<el-card shadow="hover"
							style="width: 800px;height: 650px;background-color: white;margin: 30px auto;">
							<div>
								<p
									style="font-weight: bold;font-family: SourceHanSansSC-Bold;text-align: center;font-size: 20px;">
									Citing: Being/Predicting as Highly Cited Paper</p>
								<div
									style="white-space: nowrap;line-height: 4px;writing-mode: tb-rl;text-align: right;position: absolute;margin: 238px 0px 0px -10px;font-family: PingFang-SC-Bold;font-weight: bold;">
									<p>Monthly Status</p>
									<p>2022</p>
								</div>
								<div class="chart">
									<div id="chart" :style="{ width: '700px', height: '500px',margin:'auto'}"></div>
								</div>
								<p
									style="font-weight: bold;font-family: SourceHanSansSC-Bold;text-align: center;margin-top: -40px">
									Threshod: Top 1% Highly Cited</p>
								<div style="float: right;line-height: 4px;">
									<p style="color: #b40b18;font-size: 14px;margin-top: 15px;">* Bechmark Update
										on 2022-07.</p>
									<p style="color: #b40b18;font-size: 14px;margin-top: 15px;">* The data was
										analysized from google scholars.</p>
								</div>

							</div>
						</el-card>
						<div style="width: 530px;margin: auto;">
							<el-row class="mb-4">
								<el-button type="primary" @click="leaveComments = true"
									style="background-color: #6b57a5;border-color:#6b57a5;width: 250px;">Test My Paper
								</el-button>
								<el-button @click="share = true"
									style="border-color:#fe7568;width: 250px;color: #fe7568;">
									Subscribe Reports
								</el-button>
							</el-row>
						</div>
						<div style="margin-top: 30px;padding: 10px;">
							<p style="font-weight: bold;">References</p>
							<div class="showMore" v-loading="showMoreLoading">
								<div v-for="(item,index) in References" style="display: flex;">
									<div>
										<p style="margin-right: 5px;margin-top: 1px;">
											[{{index+1}}]
										</p>
									</div>
									<div>
										<el-link :underline='false' href='javascript:void(0)'
											style="color: #333333;font-size: 16px;">{{item.ti}}
										</el-link>
										<p style="color: #666666;margin-top: 2px;">
											{{item.au}}&nbsp;&nbsp;{{item.vl}}&nbsp;&nbsp;{{item.isIs}}&nbsp;&nbsp;{{item.bp}}-{{details.ep}}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div style="margin-top: 20px;text-align: center;">
							<el-link :underline='false' href='javascript:void(0)' @click="showMore()">
								<el-icon>
									<CaretBottom />
								</el-icon> Show More
							</el-link>
						</div>
						<div style="padding: 10px;">
							<div style="line-height: 24px;padding: 0 10px 0 10px;text-align: justify;">
								<div class="fregl" style="margin-top: 120px;">
									<p>
										<span style="color: #0a0204;font-weight: bold;">Article type:&nbsp;</span>
										<span style="color: #000000;font-family: OpenSans;">{{details.dt}}</span>
									</p>
								</div>

								<div class="fregl">
									<p>
										<span style="color: #0a0204;font-weight: bold;">Authors:&nbsp;</span>
										<span style="color: #2d8dc5;font-family: OpenSans;">{{details.af}}</span>
									</p>
								</div>

								<div class="fregl">
									<p>
										<span style="color: #0a0204;font-weight: bold;"> Affiliations:&nbsp;</span>
										<span style="color: #000000;font-family: OpenSans;">{{details.cs}}</span>
									</p>


								</div>

								<div class="fregl">
									<p>
										<span style="color: #0a0204;font-weight: bold;"> Correspondence:&nbsp;</span>
										<span style="color: #000000;font-family: OpenSans;">{{details.rp}}</span>
									</p>
								</div>

								<div class="fregl" style="background-color: rgb(243, 249, 252);;padding: 10px;">
									<p>
										<span style="color: #0a0204;font-weight: bold;"> Abstract:&nbsp;</span>
										<span style="color: #000000;font-family: OpenSans;">{{details.ab}}</span>
									</p>
								</div>

								<div class="fregl">
									<p>
										<span style="color: #0a0204;font-weight: bold;"> Keywords:&nbsp;</span>
										<span style="color: #2d8dc5;font-family: OpenSans;">{{details.de}}</span>
									</p>
								</div>
								<div class="fregl">
									<p>
										<span style="color: #0a0204;font-weight: bold;"> Keywords Plus:&nbsp;</span>
										<span
											style="color: #2d8dc5;font-family: OpenSans;">{{details.KeywordsPlus}}</span>
									</p>
								</div>

								<div class="fregl">
									<p>
										<span style="color: #0a0204;font-weight: bold;"> Journal:&nbsp;</span>
										<span style="color: #2d8dc5;font-family: OpenSans;">
											{{details.vl}}&nbsp;&nbsp;{{details.isIs}}&nbsp;&nbsp;{{details.bp}}-{{details.ep}}
										</span>
									</p>

								</div>

								<div class="fregl">
									<p>
										<span style="color: #0a0204;font-weight: bold;"> DOI:&nbsp;</span>
										<span style="color: #000000;font-family: OpenSans;color: #2d8dc5;">
											{{details.di}}
										</span>
									</p>

								</div>

								<div class="fregl" style="margin-bottom: 40px;">
									<p>
										<span style="color: #0a0204;font-weight: bold;"> Published:&nbsp;</span>
										<span style="color: #000000;font-family: OpenSans;">
											{{details.py}}-{{details.pd}}
										</span>
									</p>

								</div>
							</div>
						</div>
					</div>
				</div>
				<div style=" width: 300;margin-top: 50px;">
					<div style="width: 290px;background: rgb(243, 249, 252);height: 150px;padding: 15px;">
						<p style="padding-left: 20px;"><span style="font-size: 16px;color: #4b4d4d;margin-right: 10px;">
								<el-icon>
									<Share />
								</el-icon>
							</span>
							<span style="color: #4b4d4d;font-size: 16px;font-family: PingFang-SC-Medium;">Share this
								article with:</span>
						</p>
						<ul style="display: flex;margin: 10px 0 0 6px;list-style: none;">
							<li style="margin-right: 15px;" @click="facebook()">
								<el-image style="" :src="require('@/assets/images/2_03.png')"></el-image>
							</li>
							<li style="margin-right: 15px;" @click="twitter()">
								<el-image style="" :src="require('@/assets/images/twitter.png')"></el-image>
							</li>
							<li style="margin-right: 15px;" @click="linkedin()">
								<el-image style="" :src="require('@/assets/images/2_07.png')"></el-image>
							</li>
							<!-- <li @click="weChat()">
								<el-image style="" :src="require('@/assets/images/weixinlogo.png')"></el-image>
							</li> -->
						</ul>
						<p style="padding-left: 20px;padding-top: 15px;"><span
								style="font-size: 16px;color: #4b4d4d;margin-right: 10px;">
								<el-icon>
									<Share />
								</el-icon>
							</span>
							<el-link :underline='false'
								style="color: #4b4d4d;font-size: 16px;font-family: PingFang-SC-Medium;"
								href='javascript:void(0)' @click="forwardFriend=true">Forward to a
								Friend</el-link>
						</p>
					</div>
					<div style="background: #fafafa;margin-top: 40px;padding: 15px;width: 290px;">
						<p style="font-size: 20px;">
							Editor’s Choice: Digest List
						</p>
						<el-divider style="height: 2px;width: 288px;margin-top: -12px;" />
						<el-divider style="height: 2px;width: 125px;margin-top: -26px;background-color: #6b57a5;" />
						<div style="">
							<el-collapse v-model="activeNames" @change="handleChange(activeNames)">
								<el-collapse-item :title="item.subject.newSubject" :name="index"
									v-for="(item,index) in subjects">
									<div style="padding: 14px;" class="monthList">
										<span v-if="item.month!=''" v-for="(item2,itemIntex2) in item.month"
											:key="itemIntex2" @click="tagCheck2(index,itemIntex2)">{{item2}}</span>
										<span v-else>暂无数据</span>
									</div>
								</el-collapse-item>
							</el-collapse>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog v-model="leaveComments" title="Leave Comments" width="666px" draggable :close-on-click-modal=false>
			<el-form :model="leaveCommentsForm" label-position='top' size="small" :rules="leaveCommentsFormRules"
				ref="leaveCommentsForm">
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="Organization" required prop="fieldA">
							<el-input v-model="leaveCommentsForm.fieldA" autocomplete="off" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Name" required prop="fieldB">
							<el-input v-model="leaveCommentsForm.fieldB" autocomplete="off" />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="Tel" required prop="fieldC">
							<el-input v-model.number="leaveCommentsForm.fieldC" autocomplete="off" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Email" required prop="fieldD">
							<el-input v-model="leaveCommentsForm.fieldD" autocomplete="off" />
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item label="Services of interest" required prop="fieldE">
					<el-select v-model="leaveCommentsForm.fieldE" placeholder="Please select a zone">
						<el-option label="Highly cited papers query" value="Highly cited papers query" />
						<el-option label="Highly cited papers query" value="Highly cited papers query" />
						<el-option label="Reference tracking report" value="Reference tracking report" />
						<el-option label="Papers gap query" value="Papers gap query" />
					</el-select>
				</el-form-item>
				<el-form-item label="Promotion name" prop="fieldF">
					<el-input v-model="leaveCommentsForm.fieldF" autocomplete="off" />
				</el-form-item>
				<el-form-item label="Activity form" prop="fieldG">
					<el-input v-model="leaveCommentsForm.fieldG" type="textarea" :rows="5" />
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button type="primary" @click="leaveCommentsFormSubmit('leaveCommentsForm')"
						style="background-color: #6b57a5;border-color:#6b57a5;width: 200px;">SUBMIT</el-button>
				</span>
			</template>
		</el-dialog>

		<el-dialog v-model="share" title="Share" width="666px" :close-on-click-modal=false>
			<el-form :model="shareForm" label-position='top' size="small" :rules="shareFormRules" ref="shareForm">
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="Your Name" required prop="fieldA">
							<el-input v-model="shareForm.fieldA" autocomplete="off" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Your Tel" required prop="fieldB">
							<el-input v-model.number="shareForm.fieldB" autocomplete="off" />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="Addressee Name" required prop="fieldC">
							<el-input v-model="shareForm.fieldC" autocomplete="off" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Addressee Email" required prop="fieldD">
							<el-input v-model="shareForm.fieldD" autocomplete="off" />
						</el-form-item>
					</el-col>
				</el-row>

				<el-form-item label="Mail Title" prop="fieldF">
					<el-input v-model="shareForm.fieldF" type="textarea" :rows="5" />
				</el-form-item>
				<el-form-item label="Mail Massage" prop="fieldG">
					<el-input v-model="shareForm.fieldG" type="textarea" :rows="5" />
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button type="primary" @click="shareFormSubmit('shareForm')"
						style="background-color: #6b57a5;border-color:#6b57a5;width: 200px;">SUBMIT</el-button>
				</span>
			</template>
		</el-dialog>
		<el-dialog v-model="cite" title="Citations" width="666px">
			<p><span style="font-weight: bold;margin-right: 20px;">Export to:</span><span
					style="margin-right: 20px;color: #2d8dc5;">Reference manager
					(RIS)</span>
				<span style="margin-right: 20px;color: #2d8dc5;cursor: pointer;" @click="dowCite()">Spreadsheet
					(CSV)</span>
			</p>
			<p><span style="font-weight: bold;">Citation</span><span
					style="float: right;font-weight: bold;">Style</span></p>
			<p><span>{{details.so}} {{details.vl}} {{details.isIs}} {{details.py}}</span><span
					style="font-weight: bold;float: right;">MLA</span></p>
		</el-dialog>
		<el-dialog v-model="forwardFriend" title="Forward to a Friend" width="555px">
			<p>Please provide one or more email addresses below to sharethis email with your friend(s). You may separate
				multipleemail addresses with semicolons(; ).</p>

			<el-form :model="forwardFriend" label-position='top' size="small" :rules="forwardFriendRules"
				ref="forwardFriendForm">
				<el-form-item label="Send To" required prop="fieldA">
					<el-input v-model="forwardFriendForm.fieldA" />
				</el-form-item>
				<el-form-item label="Template" prop="fieldB">
					<el-input v-model="forwardFriendForm.fieldB" />
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button type="primary" @click="send('forwardFriendForm')"
						style="background-color: #6b57a5;border-color:#6b57a5;width: 200px;">SEND</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>
<script>
	import $http from "@/request/http";
	import * as echarts from "echarts";
	export default {
		data() {
			return {
				details: {},
				References: [],
				searchValue: '',
				searchType: 'Title',
				activeNames: 0,
				leaveComments: false,
				share: false,
				forwardFriend: false,
				cite: false,
				leaveCommentsForm: {
					fieldA: '',
					fieldB: '',
					fieldC: '',
					fieldD: '',
					fieldE: '',
					fieldF: '',
					fieldG: '',
					classification: 'testMyPaper'

				},
				leaveCommentsFormRules: {
					fieldA: [{
						required: true,
						trigger: ['blur', 'change'],
						message: "Organization is required!"
					}, ],
					fieldB: [{
						required: true,
						trigger: ['blur', 'change'],
						message: "Name is required!"
					}, ],
					fieldC: [{
							required: true,
							trigger: ['blur', 'change'],
							message: "Tel is required!"
						},
						{
							type: 'number',
							message: 'Tel must be a number'
						},
					],
					fieldD: [{
							required: true,
							trigger: ['blur', 'change'],
							message: "E-mail is required!"
						},
						{
							type: 'email',
							message: 'Please input correct email address',
							trigger: ['blur', 'change'],
						},
					],
					fieldE: [{
						required: true,
						trigger: ['blur', 'change'],
						message: "Services of interest required!"
					}, ],
				},
				shareForm: {
					fieldA: '',
					fieldB: '',
					fieldC: '',
					fieldD: '',
					fieldE: '',
					fieldF: '',
					classification: 'subscribeReports'
				},
				shareFormRules: {
					fieldA: [{
						required: true,
						trigger: ['blur', 'change'],
						message: "Your Name is required!"
					}, ],
					fieldB: [{
							required: true,
							trigger: ['blur', 'change'],
							message: "Your Tel is required!"
						},
						{
							type: 'number',
							message: 'Tel must be a number'
						},
					],
					fieldC: [{
						required: true,
						trigger: ['blur', 'change'],
						message: "Addressee Name is required!"
					}, ],
					fieldD: [{
							required: true,
							trigger: ['blur', 'change'],
							message: "E-mail is required!"
						},
						{
							type: 'email',
							message: 'Please input correct email address',
							trigger: ['blur', 'change'],
						},
					],
				},
				forwardFriendForm: {
					fieldA: '',
					fieldB: '',
					classification: 'forwardToAFriend'
				},
				forwardFriendRules: {
					fieldA: [{
						required: true,
						trigger: ['blur', 'change'],
						message: "Your Name is required!"
					}, ],
				},
				paramMap: {},
				subjects: [],
				Xdata: [],
				Ydata: [],
				pageNo: 0,
				showMoreLoading: false,

			};
		},

		methods: {
			dowCite() {
				let di = this.details.di
				window.location.href = "http://103.120.82.111:8066/dowCite?di=" + di;
			},
			tagCheck2(index, itemIntex2) {
				this.paramMap.subject = this.subjects[index].subject.oldSubject
				this.paramMap.month = this.subjects[index].month[itemIntex2]
				this.paramMap.newSubject = this.subjects[index].subject.newSubject
				this.$router.push({
					name: "digests",
					params: this.paramMap
				});
			},
			getSubjectAndMonth() {
				let _this = this
				$http.post("getSubjectAndMonth")
					.then(function(res) {
						_this.subjects = res.data
					})
					.catch(function() {});
			},
			getHistogram() {
				let _this = this
				var paramMap = {
					subject: _this.details.subjectS,
					tc: _this.details.tc,
					year: _this.details.py
				}
				$http.post("histogram", paramMap, "subjectLoading")
					.then(function(res) {
						_this.Ydata = res.data.histogramYData
						_this.Xdata = res.data.histogramXData
						document.getElementById("chart").setAttribute('_echarts_instance_', '')
						_this.echartsInit()
					})
					.catch(function() {});
			},

			showMore() {
				let _this = this
				if (_this.pageNo > 0) {
					_this.showMoreLoading = true
				}
				var paramMap = {
					ut: _this.details.ut,
					pageNo: _this.pageNo + 1
				}
				$http.post("getReference", paramMap)
					.then(function(res) {
						if (res.data.length == 0) {
							_this.$notify.error({
								title: "error",
								message: "No more!",
							});
							return false
						}
						res.data.forEach(element => {
							_this.References.push(element)
							_this.pageNo = res.pageNo
						});
						_this.showMoreLoading = false

					})
					.catch(function() {});

			},
			searchData() {
				let paramsMap = {
					searchValue: this.searchValue,
					searchType: this.searchType,
				}
				this.$router.push({
					name: "digests",
					params: paramsMap
				});
			},
			facebook() {
				this.$notify.success({
					title: "success",
					message: "facebook share success!",
				});
				var u = "www.sciencealerts.org";
				var t = "sciencealerts";
				window.open("http://www.facebook.com/sharer.php?u=" + encodeURIComponent(u) + "&t=" + encodeURIComponent(
					t), "sharer", "toolbar=0,status=0,width=600, height=450,top=200,left=550");

			},
			twitter() {
				this.$notify.success({
					title: "success",
					message: "twitter share success!",
				});
				var twTitle = 'sciencealerts';
				var twUrl = this.Escape("www.sciencealerts.org");
				window.open('http://twitter.com/intent/tweet?url=' +
					encodeURIComponent(twUrl) +
					"&text=" +
					encodeURIComponent(twTitle),
					"_blank",
					"toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
				)

			},
			linkedin() {
				this.$notify.success({
					title: "success",
					message: "linkedin share success!",
				});
				var lt = 'sciencealerts';
				var lu = this.Escape("www.sciencealerts.org");
				window.open('https://www.linkedin.com/shareArticle?mini=true?&title' +
					encodeURIComponent(lt) +
					"&url=" +
					encodeURIComponent(lu),
					"_blank",
					"toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
				)

			},
			weChat() {
				this.$notify.success({
					title: "success",
					message: "weChat share success!",
				});
			},
			Escape(url) {
				url = url.replace(/\=/g, "%3D"); //"""转义
				url = url.replace(/\&/g, "%26"); //"&"转义
				return url;
			},
			handleChange(index) {


			},
			leaveCommentsFormSubmit(form) {
				let _this = this
				this.$refs[form].validate((valid) => {
					if (valid) {
						$http.post("keleAddForm", this.leaveCommentsForm, "subjectLoading")
							.then(function(res) {
								_this.$notify.success({
									title: "success",
									message: res.msg,
								});
								_this.$refs[form].resetFields();
								_this.leaveComments = false
							})
							.catch(function() {});
					} else {
						this.$notify.error({
							title: "Error",
							message: "Please check the required!",
						});
						return false;
					}
				});
			},
			shareFormSubmit(form) {
				let _this = this
				this.$refs[form].validate((valid) => {
					if (valid) {
						$http.post("keleAddForm", this.shareForm, "subjectLoading")
							.then(function(res) {
								_this.$notify.success({
									title: "success",
									message: res.msg,
								});
								_this.$refs[form].resetFields();
								_this.share = false
							})
							.catch(function() {});
					} else {
						this.$notify.error({
							title: "Error",
							message: "Please check the required!",
						});
						return false;
					}
				});
			},
			send(form) {
				let _this = this
				this.$refs[form].validate((valid) => {
					if (valid) {
						$http.post("keleAddForm", this.shareForm, "subjectLoading")
							.then(function(res) {
								_this.$notify.success({
									title: "success",
									message: res.msg,
								});
								_this.$refs[form].resetFields();
								_this.forwardFriend = false
							})
							.catch(function() {});
					} else {
						this.$notify.error({
							title: "Error",
							message: "Please check the required!",
						});
						return false;
					}
				});
			},
			echartsInit() {
				let _this = this
				let column = echarts.init(document.getElementById("chart"));
				column.setOption({
					grid: {
						left: "15",
						containLabel: true,
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross'
						},
						// formatter: function(params) {
						// 	let firstParams = params[0];
						// 	return firstParams.axisValueLabel + "<br>" + "value:" + firstParams.value
						// }

					},
					xAxis: [
						//x轴数据设置
						{
							type: "value",
							// name: "人数",
							min: 0,
							// max: 150,
							//interval: 30, //间隔数
							// splitNumber: 5,
							axisLabel: {
								formatter: "{value} ",
							},
							splitLine: {
								show: true,
								lineStyle: {
									color: [
										"#7d7d7d",
										"#7d7d7d",
										"#7d7d7d",
										"#7d7d7d",
										"#7d7d7d",
										// "#b40b18",
										"#7d7d7d",
										"#7d7d7d",
									]
								},
							},
							axisLine: {
								//这是y轴文字颜色
								// lineStyle: {
								//     color: "#65C6E7",
								// },
							},
						},
					],
					yAxis: [{
						type: "category",
						offset: 0,
						axisLine: {
							//这是x轴文字颜色
							// lineStyle: {
							//     color: "#65C6E7",
							// },
						},
						// data: ["2023 Jan.", "Nov.", "Sep.", "July.", "May.", "Mar."],
						data: _this.Ydata,
						axisPointer: {
							type: "shadow",
						},
						axisTick: {
							show: false,
						},
					}, ],
					series: [{
						// data: [0.18, 0.3, 0.5, 0.7, 0.89, 1.2],
						data: _this.Xdata,
						type: "bar",
						barWidth: 35,
						label: {
							normal: {
								show: false,
								// position: "right",
							},
						},
						itemStyle: {
							//通常情况下：
							normal: {
								color: function(params) {
									var colorList = [
										"#100964",
										"#f9d950",
										"#47a178",
										"#fd5a3b",
										"#6a005f",
										"#b40b18",
									];
									return colorList[params.dataIndex];
								},
							},
						},
					}, ],
				});
			},
		},
		created() { //6192
			this.details = this.$route.params
			this.getSubjectAndMonth()
			this.showMore()
		},
		mounted() {
			this.getHistogram()
		},

	};
</script>

<style lang="scss" scoped>
	.showMore {
		background-color: #f3f9fc;
		padding: 20px;
	}

	.input-with-select {
		margin-left: 594px;
		width: 50%;
	}

	.el-select-dropdown__item.selected {
		color: #6b57a5;
		font-weight: 700;
	}

	.monthList span {
		margin-right: 10px;
	}

	.monthList span:hover {
		cursor: pointer;
		color: #fe7568;
	}
</style>
<style>
	.el-collapse-item__header {
		background-color: rgb(250, 250, 250);

	}

	.el-link--inner:hover {
		color: #f56c6c;
	}

	.el-dialog__header {
		background: #f56c6c;
	}

	.el-dialog__headerbtn .el-dialog__close {
		color: black
	}
</style>
